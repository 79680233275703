import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import style from "./index.module.scss";
import "./dateRangePicker.scss";
import "rsuite/DateRangePicker/styles/index.css";
import { addHours, startOfDay } from "rsuite/esm/internals/utils/date";


export const adjustToUTC = (date) => {
  const utcDate = new Date(date?.getTime() - date?.getTimezoneOffset() * 60000);
  return utcDate.toISOString() // This keeps the date part only
}


const DateRangePickerShortCuts = ({
  setDate,
  className,
  type,
  placement = "bottomEnd",
  disableFuture, disablePast
}) => {
  const todays = new Date();
  const defaultToday = new Date();
  const thirtyDaysAgo = moment(todays).subtract(30, "days").startOf("day");

  const predefinedRanges = [
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
  ];

  const shouldDisableDate = (date) => {
    if (disableFuture && isAfter(date, new Date())) {
      return true;
    }
    if (disablePast && isBefore(date, new Date())) {
      return true;
    }
    return false;
  };

  const defaultVal = [ addHours(startOfDay(startOfMonth(defaultToday)),12), addHours(startOfDay(endOfMonth(defaultToday)),12)]

  return (
    <div
      className={`${type === "header-filter" ? "header-daterange" : ""} ${
        style.dateRangeWrapper
      }`}
    >
      {/* <div className={style.reportName}>User Onboarding Report</div> */}
      <div className={style.dateRangeShortCut}>
        <DateRangePicker
          className={`${style.dateRange} ${className}`}
          ranges={predefinedRanges}
          placeholder="Date Range Select"
          style={{ width: 245 }}
          character={" - "}
          editable
          format="dd/MM/yyyy"
          defaultCalendarValue={defaultVal}
          defaultValue={defaultVal}
          onShortcutClick={(shortcut) => {
            console.log("short",shortcut)

            setDate(() => ({
              start_date: adjustToUTC(shortcut?.value?.[0]),
              end_date: adjustToUTC(shortcut?.value?.[1]),
            }));
          }}
          onChange={(data) => {
            if (data?.length) {
              console.log("data",data)
              setDate(() => ({
                start_date: adjustToUTC(data?.[0]),
                end_date: adjustToUTC(data?.[1]),
              }));
            } else {
              setDate(() => ({
                start_date: thirtyDaysAgo.toISOString(),
                end_date: todays.toISOString(),
              }));
            }
          }}
          shouldDisableDate={shouldDisableDate}
          appearance={"default"}
          placement={placement}
        />
      </div>
    </div>
  );
};

export default DateRangePickerShortCuts;
