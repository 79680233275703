import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton/index";
import Back from "../../icons/Back";

const BackBtn = () => {
  const navigate = useNavigate();
  return (
    <CustomButton
      text="Back"
      icon={<Back />}
      handleClick={() => navigate(-1)}
    />
  );
};

export default BackBtn;
