import React from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { patchData, postData } from "../../../../../../services";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";
import TagInput from "../../../../../molecules/TagInput";

const UserForm = ({ onClose, initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["b_t", "bid"]);

  const initialData = {
    name: initialValues?.name || null,
    devices: null,
  };

  const validationSchema = Yup.object().shape({
    name: initialValues
      ? Yup.string().required("Device Name is required")
      : Yup.mixed().notRequired(),
    devices: initialValues
      ? Yup.mixed().notRequired()
      : Yup.array()
          .of(Yup.string().required("Each device name is required"))
          .min(1, "At least one device is required")
          .required("At least one device is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const res = await postData({
        endpoint: "BusinessConfiguration/addDevice",
        token: cookies.b_t,
        data: values.devices,
        params: { business_id: cookies.bid },
      });

      if (res?.data?.status) {
        toast.success("Designation Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        onClose();
        refetchData();
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      }
    } catch (error) {
      toast.error("Please Try Again Later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      onClose();
    }
  };

  const handleEdit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "BusinessConfiguration/editDevice",
        token: cookies.b_t,
        params: { id: id, ...values },
      });

      if (res.status) {
        toast.success("Designation Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <Form>
          {initialValues ? (
            <div className="form-group">
              <label htmlFor="name">Device Name</label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Enter Device Name"
              />
            </div>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="name">Device Names</label>
                <TagInput
                  placeholder="Add Device Name and Press Enter"
                  onChange={(values) => {
                    setFieldValue("devices", values);
                  }}
                />
              </div>
            </>
          )}

          <div className="d-flex justify-content-end mt-3">
            {initialValues ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid}
                onClick={() => handleEdit(values)}
              >
                Update
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary w-30"
                disabled={!isValid || !dirty}
                onClick={() => handleSubmit(values)}
              >
                Add
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
