import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenWidth from "../../../hooks/useScreenwidth";
import style from "./styles.module.scss";

const MultiselectFilter = ({
  title,
  handleFilter,
  data,
  icon,
  type,
  open,
  setOpen,
  setQuickSearchData,
  quickSearchData,
  id,
  dropdownPosition,
  reset,
  sizeType,
  tabresponsive = false,
}) => {
  // const [open, setOpen] = useState(false);
  const screenWidth = useScreenWidth();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [labelWidth, setLabelWidth] = useState("auto");
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(false);
  }, [screenWidth]);

  const headDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      headDropdownRef.current &&
      !headDropdownRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (reset) {
      handleReset();
    }
  }, [reset]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Filter data based on search query
    const filteredOptions = data?.filter((option) =>
      option.label?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredOptions);
  }, [searchQuery, data]);

  const handleContentClick = (event) => {
    // Prevent the click inside the dropdown content from closing the dropdown
    event.stopPropagation();
  };

  useEffect(() => {
    if (quickSearchData?.value) {
      handleOptionChange(quickSearchData);
    }
  }, [quickSearchData]);

  const handleOptionChange = (option) => {
    const isSelected = selectedOptions?.includes(option?.value);
    const updatedOptions = isSelected
      ? selectedOptions?.filter((value) => value !== option?.value)
      : [...selectedOptions, option?.value];

    setSelectedOptions(updatedOptions);
  };

  // console.log("selectedOptions", selectedOptions);

  const handleConfirm = () => {
    handleFilter(selectedOptions);
    setOpen(false);
    setQuickSearchData && setQuickSearchData(null);
  };

  const handleReset = () => {
    setSelectedOptions([]);
    setOpen(false);
    handleFilter(null);
    setQuickSearchData && setQuickSearchData(null);
    
  };
  useEffect(() => {
    if (headDropdownRef) {
      const finalWidth = headDropdownRef.current.getBoundingClientRect().width;
      setLabelWidth(finalWidth);
    }
  }, [open]);
  return (
    <div
      onClick={handleClickOpen}
      ref={headDropdownRef}
      className={
        sizeType === "small"
          ? style.small_multi_select_filter_wrapper
          : style.multi_select_filter_wrapper
      }
    >
      <div
        className={` ${style.labelWrap} ${
          open || selectedOptions?.length > 0 ? style.activeLabel : ""
        }`}
        id={id}
      >
        <label className={style.title}>{title}</label> 
        {selectedOptions?.length > 0 && (
          <div
            className={
              sizeType === "small" ? "small-indicator-dot" : style.indicator_dot
            }
          >
            {" "}
          </div>
        )}
        <i className={`d-flex`}>{icon}</i>
      </div>
      {open && (
        <div
          className={`${style.positionStyles} dropdown-content`}
          onClick={handleContentClick}
          // style={{
          //   left: `${
          //     headDropdownRef.current
          //       ? headDropdownRef.current.offsetLeft +
          //         headDropdownRef.current.offsetWidth -
          //         245
          //       : 0
          //   }px`,
          //   top: `${
          //     headDropdownRef.current
          //       ? headDropdownRef.current.offsetTop +
          //         headDropdownRef.current.offsetHeight
          //       : 0
          //   }px`,
          // }}
          // style={{
          //   ...style,
          //   left:
          //     screenWidth > 1230
          //       ? `${
          //           headDropdownRef.current
          //             ? headDropdownRef.current.offsetLeft +
          //               headDropdownRef.current.offsetWidth -
          //               245
          //             : 0
          //         }px`
          //       : screenWidth > 1230
          //       ? dropdownPosition?.left + 300 - 18 * 16 <
          //         window.innerWidth - 18 * 16
          //         ? dropdownPosition?.left - 18 * 16 > 0
          //           ? `calc(${dropdownPosition?.left}px - 17.5rem)`
          //           : `0.1px`
          //         : "auto"
          //       : dropdownPosition?.left + 300 < window.innerWidth
          //       ? dropdownPosition?.left > 0
          //         ? `calc(${dropdownPosition?.left}px - 0rem)`
          //         : "10px"
          //       : "auto",
          //   top: `${
          //     headDropdownRef.current
          //       ? headDropdownRef.current.offsetTop +
          //         headDropdownRef.current.offsetHeight
          //       : 0
          //   }px`,
          //   right:
          //     dropdownPosition?.left + 300 > window.innerWidth
          //       ? "10px"
          //       : "auto",
          // }}
          style={{ width: labelWidth }}
        >
          <div className={`${style.dataContainer}`}>
            <input
              type="text"
              placeholder="Search"
              className={style.dataInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ width: "100%" }}
            />
            {filteredData.map((option, index) => (
              <div key={index} className="mb-1">
                <label
                  className="check-container"
                  htmlFor={option.label}
                  style={{ fontSize: 16 }}
                >
                  {option.label}
                  <input
                    type="checkbox"
                    id={option.label}
                    checked={selectedOptions?.includes(option?.value)}
                    onChange={() => handleOptionChange(option)}
                  />
                  <span className="checkmark" style={{ top: 3 }}></span>
                </label>
              </div>
            ))}
          </div>

          <div className={`${style.bottomContainer}`}>
            <span
              className="medium cursor-pointer"
              onClick={() => handleReset()}
            >
              Reset
            </span>
            <span
              className={`cursor-poiner btn-primary btn btn-sm`}
              onClick={() => handleConfirm()}
            >
              Confirm
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiselectFilter;
