import React from "react";
import "./_custom_checkbox.scss";
// a wrapper class for a regular HTML checkbox
function CheckboxWrapper(props) {
  return (
    <label className="check-container" style={{ paddingLeft: "28px" }}>
      <input
        type="checkbox"
        style={{
          marginRight: "8px",
          backgroundColor: props.disabled ? "black" : "",
        }} // Adjust styling as needed
        {...props}
        className="checkbox"
      />
      <span
        className="checkmark"
        style={{
          backgroundColor: props.disabled ? "#F0F0F0" : "",
          cursor: props.disabled ? "not-allowed" : "",
        }}
      ></span>
    </label>
  );
}

export default CheckboxWrapper;
