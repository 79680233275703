export const MODE_BUSINESS = "Business";
export const MODE_EMPLOYEE = "Employee";

export const LINK_MODE_EMPLOYEE = 'employee';
export const LINK_MODE_BUSINESS = 'business';


export const USER_ROLE_TYPE = {
    OWNER: "owner",
    ADMIN: "admin",
    MANAGER: "manager",
    VIWER: "viewer",
  };