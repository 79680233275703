import { useState } from "react";
import "./styles.scss";

const ReCaptchaTerms = ({ classes }) => {
  const [learnMore, setLearnMore] = useState(false);
  return (
    <div
      className={`recaptcha-terms-of-use ${classes}`}
      data-uia="recaptcha-terms-of-use"
      style={{ textAlign: "center" }}
    >
      <div>
        <span>
          This page is protected by Google reCAPTCHA to ensure you're not a bot.
        </span>
        &nbsp;
        <button
          className="recaptcha-terms-of-use--link-button -hidden"
          data-uia="recaptcha-learn-more-button"
          type="button"
          onClick={() => setLearnMore((prev) => !prev)}
        >
          Learn more.
        </button>
      </div>
      <div>
        <span>By Continuing,you agree to our</span>
        &nbsp;
        <button
          className="recaptcha-terms-of-use--link-button -hidden"
          data-uia="recaptcha-learn-more-button"
          type="button"
          onClick={() => setLearnMore((prev) => !prev)}
        >
          TnC and Privacy Policy
        </button>
      </div>

      {learnMore && (
        <div
          className="recaptcha-terms-of-use--disclosure -visible"
          data-uia="recaptcha-disclosure"
        >
          <span id="" data-uia="recaptcha-disclosure-text">
            The information collected by Google reCAPTCHA is subject to the
            Google{" "}
            <a
              href="https://policies.google.com/privacy"
              id="recaptcha-privacy-link"
              data-uia="recaptcha-privacy-link"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#0071eb" }}
              className="recaptcha-terms-of-use--link-button"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              id="recaptcha-tos-link"
              data-uia="recaptcha-tos-link"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#0071eb" }}
              className="recaptcha-terms-of-use--link-button"
            >
              Terms of Service
            </a>
            , and is used for providing, maintaining, and improving the
            reCAPTCHA service and for general security purposes (it is not used
            for personalised advertising by Google).
          </span>
        </div>
      )}
    </div>
  );
};

export default ReCaptchaTerms;
