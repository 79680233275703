// Loader.js
const loaderContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // background: "rgba(0, 0, 0, 0.5)" /* Blackish overlay */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents : 'none'
  };
  
  const Loader = () => {
    return (
      <div style={loaderContainerStyle}>
        <div className="showbox">
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="3"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  
  export const SmallLoader = () => {
    return (
      <div>
        <div className="showbox">
          <div className="loader">
            <svg className="circular" viewBox="0 0 50 50">
              {/* Adjust the radius (r) to reduce the size */}
              <circle
                className="path"
                cx="25"
                cy="25"
                r="15"
                fill="none"
                strokeWidth="3"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  
  export const SmallerLoader = () => {
    return (
      <div className="smallerLoader">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };
  
  export default Loader;
  