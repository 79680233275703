import React, { useEffect, useState } from "react";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import './styles.scss';

const VenueDataSelection = ({ data, handleAddVenue, onClose, selectedData }) => {
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds(selectedData || []);
  }, [selectedData]);

  useEffect(() => {
    handleAddVenue(selectedIds);
  }, [selectedIds]);

  const handleSingleSelect = (value) => {
    setSelectedIds((prevSelectedIds) => {
      const newSelectedIds = prevSelectedIds.includes(value)
        ? prevSelectedIds.filter((id) => id !== value)
        : [...prevSelectedIds, value];
      return newSelectedIds;
    });
  };

  return (
    <div className="device_container pt-4 px-4">
      {data && data.map((item) => (
        <div key={item.value} className="checkbox_container mb-4">
          <label>{item.label}</label>
          <CheckboxWrapper
            checked={selectedIds.includes(item.value)}
            onChange={() => handleSingleSelect(item.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default VenueDataSelection;
