import { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import PageHeader from "../../../../atoms/PageHeader";
import BackBtn from "../../../../atoms/BackBtn";
import PageCard from "../../../../atoms/PageCard";
import Error from "../../../../atoms/Error";
import InputLayout from "../../../../atoms/InputLayout";
import style from "./style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../atoms/CustomButton";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import { postData, patchData, getData } from "../../../../../services/index";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import SelectDropdown from "../../../../molecules/Dropdown";
import {
  defaultInviteStatus,
  forceTerminl,
} from "../../../../../helpers/staticData";

const AddVenue = ({ type = "add" }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["b_t", "bid", "buid"]);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "Venue/getVenueDetai",
        token: cookies.b_t,
        params: { id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const navigate = useNavigate();
  const initialData = {
    name: initialValues?.name || null,
    address: initialValues?.address || null,
    city: initialValues?.city || null,
    postal_code: initialValues?.postal_code || null,
    state: initialValues?.state || null,
    country: initialValues?.country || null,
    description: initialValues?.description || null,
    allow_electronic_device: initialValues?.allow_electronic_device || null,
    send_email_copy_to_host: initialValues?.send_email_copy_to_host || null,
    is_phone_checkin: initialValues?.is_phone_checkin || null,
    is_code_checkin: initialValues?.is_code_checkin || null,
    is_qr_checkin: initialValues?.is_qr_checkin || null,
    allow_additional_visitor: initialValues?.allow_additional_visitor || null,
    force_checkout: initialValues.force_checkout || null,
    invite_status: initialValues.invite_status || null,
    is_notify_host_for_checkIn_checkOut:
      initialValues.is_notify_host_for_checkIn_checkOut || null,
    is_notify_invitee_for_checkIn_checkOut:
      initialValues.is_notify_invitee_for_checkIn_checkOut || null,
    created_by: cookies.buid || "",
  };

  console.log("ini-al", initialValues);
  console.log("ini-data", initialData);
  const validationSchema = yup
    .object()
    .shape({
      name: yup.string().required("Venue Name is required"),
      address: yup.mixed().required("Venue Address is required"),
      description: yup.string().required("Description is required"),
      force_checkout: yup.string().required("Force Checkout Time is required"),
      invite_status: yup.string().required("Invite Status is required"),
    })
    .test(
      "one-of-three-true",
      "At least one of Phone Check-in, Code Check-in, or QR Check-in must be true",
      function (value) {
        const { is_phone_checkin, is_code_checkin, is_qr_checkin } = value;
        if (!is_phone_checkin && !is_code_checkin && !is_qr_checkin) {
          return this.createError({
            path: "is_phone_checkin",
            message:
              "At least one of Phone Check-in, Code Check-in, or QR Check-in must be true",
          });
        }
        return true;
      }
    );

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "Venue/addVenue",
        token: cookies.b_t,
        data: { ...values, business_id: cookies.bid },
      });
      if (res) {
        toast.success("Venue Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    try {
      const res = await patchData({
        endpoint: "Venue/editVenue",
        token: cookies.b_t,
        data: { ...values, business_id: cookies.bid },
        params: { id: id },
      });
      if (res) {
        toast.success("Venue Details Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <PageHeader title={type === "add" ? "Add Venue" : "Edit Venue"}>
            <BackBtn />
          </PageHeader>

          <PageCard>
            <h4 className="fw-bolder">Venue Details</h4>
            <hr />
            <div className="row g-4 mb-3">
              <InputLayout>
                <label htmlFor="name">Venue Name</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Venue Name"
                  className={`${
                    touched.name && errors.name ? "error-input" : ""
                  }`}
                />
                {touched.name && errors.name && <Error error={errors.name} />}
              </InputLayout>
              <InputLayout>
                <label htmlFor="address">Venue Address</label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter Address"
                  className={`${
                    touched.address && errors.address ? "error-input" : ""
                  }`}
                />
                {touched.address && errors.address && (
                  <Error error={errors.address} />
                )}
              </InputLayout>
              <InputLayout>
                <label htmlFor="city">City</label>
                <Field
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Enter City"
                />
              </InputLayout>
            </div>
            <div className="row g-4 mb-3">
              <InputLayout>
                <label htmlFor="postal_code">Postal Code</label>
                <Field
                  type="text"
                  id="postal_code"
                  name="postal_code"
                  placeholder="Enter Postal Code"
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="state">State/Province</label>
                <Field
                  type="text"
                  id="state"
                  name="state"
                  placeholder="Enter State"
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="country">Country</label>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  placeholder="Enter Country"
                />
              </InputLayout>
            </div>
            <div className="row g-4 mb-3">
              <InputLayout>
                <label htmlFor="force_checkout">
                  Venue Force Checkout Configuration
                </label>
                <SelectDropdown
                  data={forceTerminl}
                  placeholder="Select Force Checkout Time"
                  handleBlur={(e) => {
                    setFieldTouched("force_checkout", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("force_checkout", option);
                  }}
                  selectedValue={initialData?.force_checkout}
                  isError={touched.force_checkout && errors.force_checkout}
                />
                {touched.force_checkout && errors.force_checkout && (
                  <Error error={errors.force_checkout} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="invite_status">Default Invite Status</label>
                <SelectDropdown
                  data={defaultInviteStatus}
                  placeholder="Select Status"
                  handleBlur={(e) => {
                    setFieldTouched("invite_status", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("invite_status", option);
                  }}
                  selectedValue={initialData?.invite_status}
                  isError={touched.invite_status && errors.invite_status}
                />
                {touched.invite_status && errors.invite_status && (
                  <Error error={errors.invite_status} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="description">Description</label>
                <Field
                  type="text"
                  as="textarea"
                  id="description"
                  name="description"
                  placeholder="Enter Description"
                  className={`${
                    touched.description && errors.description
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.description && errors.description && (
                  <Error error={errors.description} />
                )}
              </InputLayout>
            </div>

            <h4 className="fw-bolder mt-5">Venue Configuration</h4>
            <hr></hr>

            <div
              className={`mb-4 w-100 d-grid`}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // 3 columns
                gap: "20px", // Space between items
              }}
            >
              {/* First Row: 3 checkboxes */}
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`allow_electronic_device`}>
                  Allow Electronic Device
                </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="allow_electronic_device"
                  name="allow_electronic_device"
                  value={values.allow_electronic_device}
                  checked={values.allow_electronic_device}
                  onChange={() => {
                    setFieldValue(
                      "allow_electronic_device",
                      !values.allow_electronic_device
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>

              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`send_email_copy_to_host`}>
                  Send Copy of Invite Code Email to Host?
                </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="send_email_copy_to_host"
                  name="send_email_copy_to_host"
                  value={values.send_email_copy_to_host}
                  checked={values.send_email_copy_to_host}
                  onChange={() => {
                    setFieldValue(
                      "send_email_copy_to_host",
                      !values.send_email_copy_to_host
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>

              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`allow_additional_visitor`}>
                  Allow Additional Visitors
                </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="allow_additional_visitor"
                  name="allow_additional_visitor"
                  value={values.allow_additional_visitor}
                  checked={values.allow_additional_visitor}
                  onChange={() => {
                    setFieldValue(
                      "allow_additional_visitor",
                      !values.allow_additional_visitor
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
            </div>

            <div
              className={`mb-4 w-100 d-grid`}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // 2 columns for second row
                gap: "20px", // Space between items
              }}
            >
              {/* Second Row: 2 checkboxes */}
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`is_notify_host_for_checkIn_checkOut`}>
                  Notify Host for Check In / Check Out
                </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_notify_host_for_checkIn_checkOut"
                  name="is_notify_host_for_checkIn_checkOut"
                  value={values.is_notify_host_for_checkIn_checkOut}
                  checked={values.is_notify_host_for_checkIn_checkOut}
                  onChange={() => {
                    setFieldValue(
                      "is_notify_host_for_checkIn_checkOut",
                      !values.is_notify_host_for_checkIn_checkOut
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>

              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`is_notify_invitee_for_checkIn_checkOut`}>
                  Notify Invitee for Check In / Check Out
                </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_notify_invitee_for_checkIn_checkOut"
                  name="is_notify_invitee_for_checkIn_checkOut"
                  value={values.is_notify_invitee_for_checkIn_checkOut}
                  checked={values.is_notify_invitee_for_checkIn_checkOut}
                  onChange={() => {
                    setFieldValue(
                      "is_notify_invitee_for_checkIn_checkOut",
                      !values.is_notify_invitee_for_checkIn_checkOut
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
            </div>

            <h4 className="fw-bolder mt-5">Check-In Mode Configuration </h4>

            <hr></hr>
            <div
              className={`mb-4 w-100 d-grid`}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // 2 columns for second row
                gap: "20px", // Space between items
              }}
            >
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`is_phone_checkin`}>Phone Check-In</label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_phone_checkin"
                  name="is_phone_checkin"
                  value={values.is_phone_checkin}
                  checked={values.is_phone_checkin}
                  onChange={() => {
                    setFieldValue("is_phone_checkin", !values.is_phone_checkin);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`is_code_checkin`}>Invite Code Check-In</label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_code_checkin"
                  name="is_code_checkin"
                  value={values.is_code_checkin}
                  checked={values.is_code_checkin}
                  onChange={() => {
                    setFieldValue("is_code_checkin", !values.is_code_checkin);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`is_qr_checkin`}>QR Code Check-In </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_qr_checkin"
                  name="is_qr_checkin"
                  value={values.is_qr_checkin}
                  checked={values.is_qr_checkin}
                  onChange={() => {
                    setFieldValue("is_qr_checkin", !values.is_qr_checkin);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
            </div>

            <div className="d-flex gap-3 mt-5">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving}
                >
                  Create Venue
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving}
                >
                  Update
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};
export default AddVenue;
