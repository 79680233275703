import React, { useEffect, useState, useCallback } from "react";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import CheckboxWrapper from "../../../../../atoms/CheckBoxWrapper/Checkbox";

const VehicleSelection = ({
  selectedVehicles,
  handleAddVehicles,
  isDisabled,
}) => {
  const [vehicles, setVehicles] = useState([]);
  const [selected, setSelected] = useState([]);

  const [cookies] = useCookies(["b_t"]);

  const handleSingleSelect = useCallback((value) => {
    setSelected((prevSelected) => {
      if (Array.isArray(prevSelected)) {
        if (prevSelected?.includes(value)) {
          return prevSelected?.filter((id) => id !== value);
        } else {
          return [...prevSelected, value];
        }
      } else {
        return [value];
      }
    });
  }, []);

  useEffect(() => {
    handleAddVehicles(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(selectedVehicles);
  }, [selectedVehicles]);

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "AdminConfiguration/getVehicleList",
        token: cookies.b_t,
      });
      setVehicles(res);
    })();
  }, [cookies.b_t]);

  return (
    <div className="d-flex gap-5 mt-2 flex-wrap responsive-tab-gap">
      {vehicles.length > 0 &&
        vehicles.map((data) => (
          <div className="checkbox_container mb-4" key={data.value}>
            <label>{data.label}</label>
            <CheckboxWrapper
              checked={selected?.includes(data.value)}
              onChange={() => handleSingleSelect(data.value)}
              disabled={isDisabled}
            />
          </div>
        ))}
    </div>
  );
};

export default VehicleSelection;
