import React, { useState, useEffect, useCallback } from "react";
import PageHeader from "../../../atoms/PageHeader";
import Pagination from "../../../molecules/Pagination";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import CustomButton from "../../../atoms/CustomButton";
import Search from "../../../molecules/search";
import { useNavigate } from "react-router-dom";
import PageCard from "../../../atoms/PageCard";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import Terminal from "../../../icons/Terminal";
import NoContentCard from "../../../atoms/NoContentCard/index";
import NoContentIcon from "../../../icons/NocontentIcon";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { setLoading } from "../../../../store/slices/loaderSlice";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  errorStyles,
  confimationStyles,
} from "../../../../assets/styles/toast";
import { useDelete } from "../../../../hooks/useDelete";
import { getData } from "../../../../services";
import Modal from "../../../organisms/modals/Modal";
import ViewDetails from "./View";
import { render } from "@testing-library/react";
import VenueAdmin from "../configurations/tabs/VenueAdmin";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { decodeCookieValue } from "../../../../helpers/cookieHelper";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import CustomMenu from "../../../atoms/CustomMenu";

const Business = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["b_t", "bid", "br"]);
  const role = decodeCookieValue(cookies.br);

  const screenWidth = useScreenWidth();
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });

  const getBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "Venue/getAllVenues",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
          business_id: cookies.bid,
        },

        token: cookies.b_t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.b_t, currentPage, itemsPerPage]);

  useEffect(() => {
    getBusinessData();
  }, [getBusinessData]);

  const columns = [
    {
      field: "name",
      headerName: "Venue Name",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "description",
      headerName: "Venue Description",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    },
    {
      field: "address",
      headerName: "Address",
      ...(screenWidth < 1230 ? { width: 300 } : { flex: 1.5 }),
      renderCell: (params) => {
        return (
          <span
            title={`${params.row?.address}, ${params.row?.city}, ${params.row?.state}, ${params.row?.country}`}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {params.row?.address}, {params.row?.city}, {params.row?.state},{" "}
            {params.row?.country}
          </span>
        );
      },
    },
    {
      headerName: "Action",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={(event) => handleStatusMenu(event, params.row)}>
              <HorizontalMenu />
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </div>
        );
      },
    },
  ];
  const menu = [
    {
      icon: <Terminal />,
      label: "Terminal",
      onClick: () => navigate(`${selectedRow?.id}/terminals`),
    },
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        setAnchorEl(null);
        handleView(selectedRow);
      },
    },
  ];
  if (role !== "Viewer") {
    menu.push(
      {
        icon: <Edit height="20" width="20" />,
        label: "Edit",
        onClick: () => {
          navigate(`edit/${selectedRow?.id}`);
        },
      },
      {
        icon: <Delete />,
        label: "Delete",
        onClick: () => {
          setAnchorEl(null);
          handleDeleteModal(selectedRow);
        },
      }
    );
  }

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue || role === "Viewer" ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Venue Added Yet!"
            subtitle="Click on  Add Venue button to add a venue"
            tag="Add Venue"
            handleClick={() => navigate("/dashboard/venues/add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // deletion --------------------------------------------
  const deleteVenue = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteVenue("Venue/deleteVenue", "Venue", {
        id: modalState.modalId,
      });
      getBusinessData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  // handle view

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      name: data.name,
      data,
    }));
  };

  return (
    <>
      <PageHeader title="Venues" />
      <PageCard>
        <div className="d-flex w-100 mb-2 justify-content-between responsive-tab-gap">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
            placeholder="Search By Venue Name"
          />
          <CustomButton
            text="Add New Venue"
            type="btn-primary"
            handleClick={() => navigate("add")}
          />
        </div>

        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />

        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}

        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Venue"
            onClick={handleDeletion}
          />
        )}

        {modalState.isViewModalOpen && (
          <Modal
            name={`${modalState.name} Details`}
            title={`${modalState.name} Details`}
            id={modalState.modalId}
            isActive={modalState.isViewModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isViewModalOpen: false,
              }))
            }
            width="642px"
          >
            <ViewDetails data={modalState.data} />
          </Modal>
        )}
      </PageCard>
    </>
  );
};

export default Business;
