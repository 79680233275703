import React from "react";
import ImageAndInitialViewer from "../../../../molecules/ImageAndInitialViewer";
import moment from "moment";
import { convertUtcToLocalTime } from "../../../../../helpers/convertUtcToLocalTime";

const commonStyle = {
  color: "#1a1d1f",
  fontWeight: "500",
  fontSize: "14px",
};

const commonLight = {
  ...commonStyle,
  color: "#6f767e",
};
const LabelValue = ({ label, value, isImg = false }) => {
  return (
    <>
      {value &&
        (typeof value == "string" ? value != "null" : value != null) && (
          <div className="d-flex w-100 mb-3 fw-bold align-items-center">
            <div className="w-40 d-flex gap-2 justify-content-between">
              <div
                style={{
                  ...commonLight,
                }}
              >
                {label}
              </div>{" "}
              :
            </div>
            {isImg ? (
              <div className="ms-4">
                <ImageAndInitialViewer image={value} />
              </div>
            ) : (
              <div
                className="w-50 ms-4 fw-bold"
                style={{
                  ...commonStyle,
                }}
              >
                {value}
              </div>
            )}
          </div>
        )}
    </>
  );
};
const VisitorLogsDetails = ({ data }) => {
  return (
    <>
      <LabelValue
        label={"CheckIn Photo"}
        value={data.check_in_photo}
        isImg={true}
      />
      <LabelValue label={"Name"} value={data.name} />
      <LabelValue label={"Venue Name"} value={data.venue_name} />
      <LabelValue label={"Host Name"} value={data.employee_name} />
      <LabelValue label={"Contact No"} value={data.contact_no} />
      <LabelValue label={"Email"} value={data.email} />
      <LabelValue label={"Company"} value={data.company} />
      <LabelValue label={"Address"} value={data.address} />
      <LabelValue label={"Status"} value={data.status} />
      <LabelValue
        label={"Visit Date"}
        value={moment(data.date).format("DD/MM/YYYY")}
      />
      <LabelValue
        label={"CheckIn Time"}
        value={
          data.check_in_time
            ? moment(data?.check_in_time).format("HH:mm")
            : "null"
        }
      />
      <LabelValue
        label={"CheckOut Time"}
        value={
          data.check_out_time
            ? moment(data?.check_out_time).format("HH:mm")
            : "null"
        }
      />
      {data && data.addition_visitor.length > 0 && (
        <>
          <div className=" mb-3 fw-bold fs-6 ">Additional Visitors</div>
          {data.addition_visitor?.map((item, index) => (
            <div
              className="d-flex w-100 mb-3 fw-bold align-items-center"
              key={index}
            >
              <div className="w-40 d-flex gap-2 justify-content-between">
                <div
                  style={{
                    ...commonLight,
                  }}
                >
                  Visitor {index + 1}
                </div>{" "}
                :
              </div>
              <div className="d-flex w-50 align-items-center">
                <div
                  className="w-60 ms-4 fw-bold text-wrap"
                  style={{
                    overflowWrap: "anywhere",
                  }}
                >
                  {item.visitor_name}
                </div>
                {item.phone_number !== "null" && (
                  <div
                    className="w-40 ms-4 fw-bold d-flex gap-1"
                    style={{
                      ...commonStyle,
                    }}
                  >
                    {item.country_code && item.phone_number && (
                      <div>{item.country_code}</div>
                    )}
                    {item.phone_number}
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
export default VisitorLogsDetails;
