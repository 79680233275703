import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";

// Layouts
import AuthLayout from "../components/layouts/AuthLayout";
import DashboardLayout from "../components/layouts/DashboardLayout";

import None from "../components/pages/none";

// Auth
import Login from "../components/pages/auth/login";
import SignUp from "../components/pages/auth/signup";
import Verification from "../components/pages/auth/verification";
import ResetOrCreatePass from "../components/pages/auth/resetOrCreatePass";
import ForgotPassword from "../components/pages/auth/forgotPassword";

// Dashboard
import Home from "../components/pages/dashboard/home";
import Venues from "../components/pages/dashboard/venues";
import Activities from "../components/pages/dashboard/activities";

import Employees from "../components/pages/dashboard/employees";
import AddEmployees from "../components/pages/dashboard/employees/Add";

import Invites from "../components/pages/dashboard/invites";
import AddInvites from "../components/pages/dashboard/invites/Add";

import EditProfile from "../components/pages/dashboard/edit-profile";
import EmployeeEditProfile from "../components/pages/dashboard/edit-profile/EmployeeEditProfile";
import ChangePassword from "../components/pages/dashboard/edit-profile/change-password";

import Logs from "../components/pages/dashboard/logs";

import Configurations from "../components/pages/dashboard/configurations";
import AddVenues from "../components/pages/dashboard/venues/Add";
import Terminals from "../components/pages/dashboard/venues/Terminals";
import AddTerminal from "../components/pages/dashboard/venues/Terminals/Add";
import VistorHistory from "../components/pages/dashboard/visitor-history/index";

import NotFound from "../components/pages/not-found";
import {
  MODE_EMPLOYEE,
  MODE_BUSINESS,
  USER_ROLE_TYPE,
} from "../helpers/constants";
import AcceptInvite from "../components/pages/dashboard/invites/mail/Accept";
import RejectInvite from "../components/pages/dashboard/invites/mail/Reject";

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "/404",
    element: <NotFound />,
  },
  {
    path: "invite/accepted/:invite_id?/:barcode?",
    element: <AcceptInvite />,
  },
  {
    path: "invite/rejected/:invite_id?",
    element: <RejectInvite />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetOrCreatePass type="reset" />,
      },
      {
        path: "create-password",
        element: <ResetOrCreatePass type="create" />,
      },
    ],
  },
  {
    path: "dashboard/business/*",
    element: <DashboardLayout mode="Business" />,
  },
  {
    path: "dashboard/employee/*",
    element: <DashboardLayout mode="Employee" />,
  },
  {
    path: "none",
    element: <None />,
  },
];

export const dashboardRoute = (
  <Suspense>
    <Routes>
      <Route
        index
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="home/:query?"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="venues"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Venues />
          </AuthGuard>
        }
      />
      <Route
        path="venues/add"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddVenues />
          </AuthGuard>
        }
      />
      <Route
        path="venues/edit/:id"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddVenues type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="venues/:venue_id/terminals"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Terminals />
          </AuthGuard>
        }
      />
      <Route
        path="venues/:venue_id/terminals/add"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddTerminal mode={MODE_BUSINESS} />
          </AuthGuard>
        }
      />
      <Route
        path="venues/:venue_id/terminals/edit/:id"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddTerminal type="edit" mode={MODE_BUSINESS} />
          </AuthGuard>
        }
      />
      <Route
        path="employees"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Employees mode={MODE_BUSINESS} />
          </AuthGuard>
        }
      />
      <Route
        path="employees/add"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddEmployees mode={MODE_BUSINESS} />
          </AuthGuard>
        }
      />
      <Route
        path="employees/edit/:id"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddEmployees type="edit" mode={MODE_BUSINESS} />
          </AuthGuard>
        }
      />
      <Route
        path="activities"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Activities />
          </AuthGuard>
        }
      />
      <Route
        path="invites"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <Invites mode={MODE_BUSINESS} />
          </AuthGuard>
        }
      />
      <Route
        path="invites/add"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddInvites mode="Business" />
          </AuthGuard>
        }
      />

      <Route
        path="invites/edit/:id"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <AddInvites type="edit" mode="Business" />
          </AuthGuard>
        }
      />
      <Route
        path="configurations"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <Configurations />
          </AuthGuard>
        }
      />

      <Route
        path="logs"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
            ]}
          >
            <Logs />
          </AuthGuard>
        }
      />

      <Route
        path="edit-profile"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <EditProfile />
          </AuthGuard>
        }
      />

      <Route
        path="edit-profile/change-password"
        element={
          <AuthGuard
            mode={MODE_BUSINESS}
            allowed={[
              USER_ROLE_TYPE.OWNER,
              USER_ROLE_TYPE.ADMIN,
              USER_ROLE_TYPE.MANAGER,
              USER_ROLE_TYPE.VIWER,
            ]}
          >
            <ChangePassword />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export const dashboardEmployeeRoutes = (
  <Suspense>
    <Routes>
      <Route
        // path="/"
        index
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="home/:query?"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <Home />
          </AuthGuard>
        }
      />

      <Route
        path="visitor-history"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <VistorHistory />
          </AuthGuard>
        }
      />
      <Route
        path="employees"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <Employees mode="Employee" />
          </AuthGuard>
        }
      />
      <Route
        path="employees/add"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <AddEmployees mode="Employee" />
          </AuthGuard>
        }
      />
      <Route
        path="employees/edit/:id"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <AddEmployees type="edit" mode="Employee" />
          </AuthGuard>
        }
      />
      <Route
        path="invites"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <Invites mode="Employee" />
          </AuthGuard>
        }
      />
      <Route
        path="invites/add"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <AddInvites mode="Employee" />
          </AuthGuard>
        }
      />

      <Route
        path="invites/edit/:id"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <AddInvites type="edit" mode="Employee" />
          </AuthGuard>
        }
      />

      <Route
        path="edit-profile"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <EmployeeEditProfile/>
          </AuthGuard>
        }
      />

      <Route
        path="edit-profile/change-password"
        element={
          <AuthGuard mode={MODE_EMPLOYEE}>
            <ChangePassword mode={MODE_EMPLOYEE}/>
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export default routes;
