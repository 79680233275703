import { getData } from "./index";

export const getDeviceList = async ({ business_id, token }) => {
  const res = await getData({
    endpoint: "BusinessConfiguration/getDeviceList",
    params: { business_id: business_id },
    token,
  });
  return res;
};

export const getDepartmentList = async ({ business_id, token }) => {
  const res = await getData({
    endpoint: "BusinessConfiguration/getDepartmentList",
    params: { business_id: business_id },
    token,
  });
  return res;
};

export const getDesignationList = async ({ business_id, token }) => {
  const res = await getData({
    endpoint: "BusinessConfiguration/getDesignationList",
    params: { business_id: business_id },
    token,
  });
  return res;
};

export const getVenueList = async ({ business_id, emp_id, token }) => {
  const res = await getData({
    endpoint: "Venue/getVenueList",
    params: { business_id: business_id, emp_id: emp_id },
    token,
  });
  return res;
};

export const getEmployeeVenueList = async ({ emp_id, token }) => {
  const res = await getData({
    endpoint: "Employe/getEmployeeVenueList",
    params: { emp_id: emp_id },
    token,
  });
  return res;
};

export const getVisitorList = async ({ business_id, token }) => {
  const res = await getData({
    endpoint: "Invite/getVisitorList",
    params: { business_id: business_id },
    token,
  });
  return res;
};

export const getParticularEmployeeVenueList = async ({
  business_id,
  emp_id,
  token,
}) => {
  const res = await getData({
    endpoint: "BusinessConfiguration/getVenueList",
    params: { business_id: business_id, emp_id: emp_id },
    token,
  });
  return res;
};

export const getEmployeeList = async ({ business_id, token, venue_id}) => {
  const res = await getData({
    endpoint: "Employe/getEmployeeList",
    params: { business_id: business_id , venue_id : venue_id},
    token,
  });
  return res;
};

export const getInviteeTypeList = async ({ business_id, token }) => {
  const res = await getData({
    endpoint: "BusinessConfiguration/getInviteeTypeList",
    params: { business_id: business_id },
    token,
  });
  return res;
};
