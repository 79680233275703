import React from "react";
import style from "./styles.module.scss";

const PageHeader = ({
  title,
  children,
  type = "large",
  className,
  removeHeader = false,
}) => {
  const headerClass =
    type === "small" ? style.small_page_header : style.page_header;

  return (
    <div
      className={`d-flex align-items-center justify-content-between mb-10 ${
        removeHeader && "tab-employee-responsive-none"
      }`}
    >
      <h3 className={`${headerClass} ${className} mb-0`}>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export default PageHeader;
