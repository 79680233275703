

import React, { useState, useEffect } from "react";
import Error from "../../atoms/Error";
import { getFileName } from "../../../helpers/fileName";
import './styles.scss'
import Upload from "../../icons/Upload";

const CustomDropzone = ({ onFileSelect, adType, id, type, selectedFile, description, mainDescription , maxFileSizeMB = 5}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null); // State for error message
  const [fileName , setFileName] = useState(null)

    // Use useEffect to update the file state when the selectedFile prop changes
    useEffect(() => {
      if (selectedFile) {
        setFileName(getFileName(selectedFile))
        setFile(selectedFile);
      }
    }, [selectedFile]);


  let allowedFileTypes = null;

  if (adType === "Document") {
    allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.ms-excel", "text/csv"];
  } else if (adType === "Excel") {
    allowedFileTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  } else if (adType === "Audio") {
    allowedFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"]; // Add more audio formats as needed
  } else if (adType === "Pdf") {
    allowedFileTypes = ["application/pdf"];
  }  else if (adType === "Image") {
    allowedFileTypes = ["image/png", "image/jpeg","image/svg+xml"];
  }
   else if (adType === 'Others') {
    allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/csv",
      "application/vnd.ms-powerpoint", // PPT
      "application/vnd.openxmlformats-officedocument.presentationml.presentation" // PPTX
  ];
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const droppedFile = e.dataTransfer.files[0];
    validateAndSetFile(droppedFile);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    validateAndSetFile(selectedFile);
  };

  const validateAndSetFile = (file) => {
    if (isFileTypeAllowed(file.type) && isFileSizeAllowed(file.size)) {
      setFile(file);
      setFileName(file.name)
      setError(null); // Reset error state
      readFileContents(file);
    } else {
      setError(generateErrorMessage(file.type, file.size));
      setFile(null); // Clear the file state
      setFileName(null)
    }
  };

  const isFileTypeAllowed = (fileType) => {
    return allowedFileTypes ? allowedFileTypes.includes(fileType) : true;
  };

  const isFileSizeAllowed = (fileSize) => {
    const maxSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes
    return fileSize <= maxSizeBytes;
  };

  const generateErrorMessage = (fileType, fileSize) => {
    let errorMessage = "File type or size not allowed.";

    if (!isFileTypeAllowed(fileType)) {
      errorMessage = "File type not supported. Please upload a valid File.";
    } else if (!isFileSizeAllowed(fileSize)) {
      errorMessage = `{File size must be less than ${maxFileSizeMB} MB.}`;
    }

    return errorMessage;
  };

  const readFileContents = (file) => {
    const reader = new FileReader();

    if (type === 'aum') {
      onFileSelect(file);
    } else {
      reader.onload = (e) => {
        const fileContents = e.target.result;
        // onFileSelect(file); // Emit the file contents to the parent component
      };

      reader.readAsDataURL(file);
      onFileSelect(file);
    }
  };

  return (
    <div
      className={`dropzone ${isDragOver ? "drag-over" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={() => document.querySelector(`#dropzone-${id}-file-input`).click()}
      key={id}
    >
      <input
        type="file"
        id={`dropzone-${id}-file-input`}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Upload/>
      <p className="mb-1">{mainDescription ? mainDescription :'Drag drop a file here, or click to select a file' }</p>
      {description && <p style={{fontSize : '12px'}} className="mb-0">{description}</p>}
      <p className="mb-0"style={{fontSize : '12px'}} >Upto {maxFileSizeMB} Mb is allowed </p>

      {error && <Error error={error}/>}

      {file && !error && (
        <p className="mb-0">
          <b>Selected File :</b>
          &nbsp;{fileName}
        </p>
      )}
    </div>
  );
};

export default CustomDropzone;


