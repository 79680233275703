import { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import "./styles.scss";

const ColorPicker = ({ onChange, selected }) => {
  const [selectedColor, setSelectedColor] = useState("#6B66DA"); // Initial color
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null); // Create a ref for the picker

  useEffect(() => {
    if (selected) {
      setSelectedColor(selected);
    } else {
      setSelectedColor('#6B66DA')
    }
  }, [selected]);

  // Function to handle color change
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onChange(color.hex);
  };

  // Function to close the picker when clicked outside
  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  // Attach event listener to detect clicks outside
  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount or when showPicker changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <div className="color-picker-container">
      <div
        className="d-flex align-items-center color-data-container"
        onClick={() => setShowPicker(!showPicker)}
      >
        <div
          className="selected-color-preview"
          style={{ backgroundColor: selectedColor }}
        ></div>
        <div>{selectedColor}</div>
      </div>

      {showPicker && (
        <div ref={pickerRef}>
          {" "}
          {/* Use the ref here */}
          <SketchPicker
            color={selectedColor}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
