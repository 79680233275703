import React from 'react';

const HorizontalMenu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="4"
      viewBox="0 0 17 4"
      fill="none"
    >
      <path
        d="M0 1.66667C0 1.337 0.09775 1.01484 0.280883 0.740753C0.464017 0.466669 0.724317 0.253003 1.02886 0.126836C1.33341 0.00075262 1.66852 -0.0322808 1.99182 0.0320026C2.31512 0.0963359 2.61209 0.255086 2.84517 0.48817C3.07827 0.721253 3.237 1.01825 3.30131 1.3415C3.36562 1.66484 3.33261 1.99992 3.20647 2.3045C3.08032 2.609 2.8667 2.86934 2.59262 3.05242C2.31853 3.23559 1.9963 3.33334 1.66667 3.33334C1.22464 3.33334 0.800717 3.15775 0.488158 2.84517C0.175592 2.53259 0 2.10867 0 1.66667ZM8.33333 3.33334C8.663 3.33334 8.98517 3.23559 9.25925 3.05242C9.53333 2.86934 9.747 2.609 9.87317 2.3045C9.99925 1.99992 10.0323 1.66484 9.968 1.3415C9.90367 1.01825 9.74492 0.721253 9.51183 0.48817C9.27875 0.255086 8.98175 0.0963359 8.6585 0.0320026C8.33517 -0.0322808 8.00008 0.00075262 7.6955 0.126836C7.391 0.253003 7.13067 0.466669 6.94758 0.740753C6.76442 1.01484 6.66667 1.337 6.66667 1.66667C6.66667 2.10867 6.84225 2.53259 7.15483 2.84517C7.46742 3.15775 7.89133 3.33334 8.33333 3.33334ZM15 2.82592e-06C14.6703 2.82592e-06 14.3482 0.0977529 14.0741 0.28092C13.8 0.464003 13.5863 0.724336 13.4602 1.02884C13.3341 1.33342 13.3011 1.6685 13.3653 1.99184C13.4297 2.31509 13.5884 2.61209 13.8215 2.84517C14.0546 3.07825 14.3516 3.237 14.6748 3.30134C14.9982 3.36559 15.3333 3.33259 15.6378 3.2065C15.9423 3.08034 16.2027 2.86667 16.3857 2.59259C16.5689 2.3185 16.6667 1.99634 16.6667 1.66667C16.6667 1.22467 16.4911 0.800753 16.1785 0.48817C15.8659 0.175586 15.442 2.82592e-06 15 2.82592e-06Z"
        fill="#555F6D"
      />
    </svg>
  );
};

export default HorizontalMenu;
