import axios from "../axios"; // Adjust the path accordingly

export const postData = async ({ endpoint, data, params, token , headerType = 'Business'}) => {
  try {
    // Set isLoading to true before the API call starts
    const isLoading = true;

    const response = await axios.post(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        'User-Role' : headerType
      },
    });

    // Set isLoading to false when the API call is complete

    const result = { data: response.data };

    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

export const postFormData = async ({ endpoint, data, params, token , headerType = 'Business'}) => {

  try {
    // Set isLoading to true before the API call starts
    const isLoading = true;

    const response = await axios.post(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        'User-Role' : headerType
      },
    });

    // Set isLoading to false when the API call is complete
    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

export const deleteData = async ({ endpoint, params, token , headerType = 'Business'}) => {
  try {
    // Set isLoading to true before the API call starts
    const isLoading = true;

    const response = await axios.delete(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        'User-Role' : headerType

      },
    });

    // Set isLoading to false when the API call is complete
    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error deleting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

// -------------------------------------------------------------------------------------

export const patchData = async ({ endpoint, data, params, token , headerType = 'Business'}) => {
  try {
    const response = await axios.patch(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        'User-Role' : headerType

      },
    });
    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};

export const patchFormData = async ({ endpoint, data, params, token , headerType = 'Business'}) => {
  try {
    const response = await axios.patch(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        'User-Role' : headerType

      },
    });
    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};

export const getData = async ({ endpoint, params, token, headerType = 'Business' }) => {
  try {
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        'User-Role' : headerType
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};
