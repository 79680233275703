import React, { useEffect, useState, useCallback } from "react";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import "./styles.scss";

const VenueDataSelection = ({
  data,
  handleAddVenue,
  onClose,
  selectedData,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds(selectedData || []);
  }, []);

  useEffect(() => {
    handleAddVenue(selectedIds);
  }, [selectedIds]);

  const handleSingleSelect = useCallback((value) => {
    setSelectedIds((prevSelectedIds) => {
      const newSelectedIds = prevSelectedIds.includes(value)
        ? prevSelectedIds.filter((id) => id !== value)
        : [...prevSelectedIds, value];
      return newSelectedIds;
    });
  }, []);

  return (
    <div
      className="venue_container pt-4 px-4 flex-wrap"
      style={{ rowGap: "10px" }}
    >
      {data.map((item) => (
        <div className="checkbox_container mb-4" key={item.value}>
          <label>{item.label}</label>
          <CheckboxWrapper
            checked={selectedIds.includes(item.value)}
            onChange={() => handleSingleSelect(item.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default VenueDataSelection;
