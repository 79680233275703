import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './style.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTimePicker = ({
  onChange,
  placeholder = "Select",
  selectedTime,
  isDisabled = false,
  handleBlur,
  isError,
  minTime,
  maxTime,
}) => {
  const handleDateChange = (time) => {
    if (onChange) {
      // Convert local time back to UTC
      const utcTime = time.utc().format("HH:mm:ss");
      onChange(utcTime);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`time-input-component ${isError ? "error-container" : ""}`}
        tabIndex={0}
      >
        <TimePicker
          views={["hours", "minutes"]}
          timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
          slotProps={{
            textField: { placeholder: `${placeholder}`, onBlur: handleBlur },
            field: {
              readOnly: true,
              onBlur: handleBlur,
            },
          }}
          className="time-picker"
          // Convert selectedTime from UTC to local time for display
          value={selectedTime ? dayjs.utc(selectedTime, "HH:mm:ss").local() : null}
          onAccept={handleDateChange}
          disabled={isDisabled}
          minTime={minTime ? dayjs.utc(minTime, "HH:mm:ss").local() : null}
          maxTime={maxTime ? dayjs.utc(maxTime, "HH:mm:ss").local() : null}
          closeOnSelect={false}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
