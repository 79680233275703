import React from "react";
import { Outlet, } from "react-router-dom";
import Navbar from "../../molecules/navbar";
import Footer from "../../molecules/footer";

import styles from "./dashboard.module.scss";
import { dashboardRoute , dashboardEmployeeRoutes} from "../../../routes/routes";
import { useCookies } from "react-cookie";


     
const DashboardLayout = ({mode}) => {
  const [cookies] = useCookies(["isa",'role','mode']);
  const isExpired = atob(cookies.isa || '');

  return (
    <div className={styles.dashboard}>
      
      {isExpired === 'true' && (
        <div className={styles.dashboard_plan_expiry}>
          The Subscription Plan is expired
        </div>
      )}
       <Navbar mode={mode}/>
      <div
        className={styles.dashboard_body}
        style={{ pointerEvents: isExpired == 'true'  && "none" }}
      >
        <Outlet />
        {mode==='Employee' ? dashboardEmployeeRoutes : dashboardRoute}
      </div>
      <Footer />
    </div>
  );
};

export default DashboardLayout;
