import React, { useState } from "react";
import IconCircle from "../../../../atoms/IconCircle";
import moment from 'moment';
import "./index.scss";

import Terminal from '../../../../icons/Terminal'
import Employees from '../../../../icons/Employees'
import Invites from '../../../../icons/Invites'
import Venues from '../../../../icons/Venues'

const DetailCard = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const getColor = (module) => {
    switch (module) {
      case "Terminal":
        return "#B1E5FC";
      case "Employee":
        return "#CABDFF";
      case 'Venue':
        return '#FFB7B7'
      case 'Invite' :
        return '#B7D4FF'
      default:
        return "#E5E0DB";
    }
  };

  const icon = (module) => {
    switch (module) {
      case "Terminal":
        return <Terminal/>;
      case "Employee":
        return <Employees/>;
      case "Venue":
        return <Venues/>
      case 'User Management':
        return <i className="fi fi-rr-users"></i>
      case 'Login':
        return <i className="fi fi-rr-user-lock"></i>
    case 'Invite':
      return <Invites/>
      default :
      return <i className="fi fi-rr-user-lock"></i>
      
    }
  };
  return (
    <div className="activity-detail-container">
      <div className="activity-content">
        <IconCircle color={getColor(data?.module)} icon={icon(data?.module)} />

        <div className="activity-info">
          <div className="info-right">
            <div className="action-detail">
              <div className="action">{data?.action}</div>
              <div className="action-time">
              {moment(data?.date).format("DD/MM/YYYY hh:mm a")}

              </div>
            </div>

            <div className="action-description">
              {data?.user}&nbsp;&nbsp;
              <span onClick={toggleExpand} className="view-more">
                {expanded ? "View less" : "View more"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {expanded && (
        <div className="expanded-info">
          <div className="expanded-table">
            <table className="expanded-table">
              <thead>
                <tr>
                  <th>Action Performed</th>
                  <th>Field</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {data?.log_details?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom:
                        index === data.log_details.length - 1
                          ? "none"
                          : "1px solid #ddd",
                    }}
                  >
                    <td>{item.action_performed || "--"}</td>
                    <td>{item.field_name || "--"}</td>
                    <td>{item.old_value || "--"}</td>
                    <td>{item.new_value || "--"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailCard;
