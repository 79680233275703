import './styles.scss'
import Close from '../../icons/Close'

const CloseButton = ({ onClose }) => {
    return (
      <div className="close-btn" onClick={onClose}>
        <Close/>
      </div>
    );
  };

  export default CloseButton