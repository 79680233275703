import { useEffect } from "react";
import { postData, postFormData } from "../../../../../services";
import { useLocation, useParams } from "react-router-dom";

const RejectInvite = () => {
  const location = useLocation();

  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const inviteId = getQueryParams("invite_id");

  const handleConfirmStatus = async () => {
    try {
      const res = await postFormData({
        endpoint: "Invite/respondToInvitation",
        params: {
          status: "Reject",
        },
        data: {
          invite_id: inviteId,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleConfirmStatus();
  }, []);

  return (
    <div
      className=" d-flex  justify-content-center"
      style={{
        height: "100vh",
        width: "100vw",
        fontSize: "24px",
        fontWeight: "500",
        alignItems: "center",
      }}
    >
      Your Invitation Is Rejected
    </div>
  );
};
export default RejectInvite;
