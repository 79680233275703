import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import View from "../../../../icons/View";
import Edit from "../../../../icons/Edit";
import Delete from "../../../../icons/Delete";
import NoContentCard from "../../../../atoms/NoContentCard";
import NoContentIcon from "../../../../icons/NocontentIcon";
import PageHeader from "../../../../atoms/PageHeader";
import PageCard from "../../../../atoms/PageCard";
import Search from "../../../../molecules/search";
import CustomButton from "../../../../atoms/CustomButton";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import Pagination from "../../../../molecules/Pagination";
import { useDelete } from "../../../../../hooks/useDelete";
import DeleteModal from "../../../../organisms/modals/DeleteModal";
import { getData, patchData } from "../../../../../services";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import toast from "react-hot-toast";
import {
  errorStyles,
  confimationStyles,
} from "../../../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import Modal from "../../../../organisms/modals/Modal";
import ViewDetails from "./View/index";
import TerminalCheckout from "../../../../icons/terminalCheckout.jsx";
import useScreenWidth from "../../../../../hooks/useScreenwidth.js";
import { decodeCookieValue } from "../../../../../helpers/cookieHelper.js";
import HorizontalMenu from "../../../../icons/HorizontalMenu.jsx";
import CustomMenu from "../../../../atoms/CustomMenu/index.jsx";

const Terminals = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["b_t", "bid", "br"]);
  const role = decodeCookieValue(cookies.br);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });
  const [terminalModal, setTerminalModal] = useState({
    isTerminalModalOpen: false,
    modalId: "",
    name: "",
  });
  const getTerminalData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "VenueTerminal/getAllVenueTerminal",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
          venue_id: venue_id,
        },

        token: cookies.b_t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.b_t, currentPage, itemsPerPage]);

  useEffect(() => {
    getTerminalData();
  }, [getTerminalData]);

  const columns = [
    {
      field: "terminal_name",
      headerName: "Terminal Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Terminal Description",
      flex: 1,
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          // <div className="table-icons-container">
          //   <div onClick={() => handleView(params.row)}>
          //     <View />
          //   </div>
          //   {role !== "Viewer" && (
          //     <>
          //       <div
          //         onClick={() =>
          //           navigate(`edit/${params.row.id}`, {
          //             state: { name: tableData?.venue_name },
          //           })
          //         }
          //       >
          //         <Edit />
          //       </div>
          //       <div onClick={() => handleDeleteModal(params.row)}>
          //         <Delete />
          //       </div>
          //       {params?.row?.has_checked_in_users && (
          //         <div onClick={() => handleTerminalModal(params.row)}>
          //           <TerminalCheckout />
          //         </div>
          //       )}
          //     </>
          //   )}
          // </div>
          <div className="table-icons-container">
            <div onClick={(event) => handleStatusMenu(event, params.row)}>
              <HorizontalMenu />
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </div>
        );
      },
    },
  ];

  const menu = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        setAnchorEl(null);
        handleView(selectedRow);
      },
    },
    ...(role !== "Viewer"
      ? [
          {
            icon: <Edit />,
            label: "Edit",
            onClick: () =>
              navigate(`edit/${selectedRow.id}`, {
                state: { name: tableData?.venue_name },
              }),
          },
          {
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setAnchorEl(null);
              handleDeleteModal(selectedRow);
            },
          },
          ...(selectedRow?.has_checked_in_users
            ? [
                {
                  icon: <TerminalCheckout />,
                  label: "Terminal Checkout",
                  onClick: () => {
                    setAnchorEl(null);
                    handleTerminalModal(selectedRow);
                  },
                },
              ]
            : []),
        ]
      : []),
  ];

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteTerminal = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.terminal_name,
    }));
  };

  const handleTerminalModal = (data) => {
    setTerminalModal((prevState) => ({
      ...prevState,
      isTerminalModalOpen: true,
      modalId: data.id,
      name: data.terminal_name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteTerminal("VenueTerminal/deleteVenueTerminal", "Terminal", {
        terminal_id: modalState.modalId,
      });
      getTerminalData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handleTerminalCheckout = async () => {
    try {
      await patchData({
        endpoint: "VenueTerminal/forceCheckout",
        token: cookies.b_t,
        params: { terminal_id: terminalModal?.modalId },
      });
      getTerminalData();
      setTerminalModal((prevState) => ({
        ...prevState,
        isTerminalModalOpen: false,
      }));
      toast.success("Terminal Chekout Succesfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } catch (error) {
      toast.error("An Error Occured While Checkout", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handleView = async (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      name: data.terminal_name,
      data: data,
    }));
  };
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue || role === "Viewer" ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Terminal Added Yet!"
            subtitle="Click on Add Terminal button to add a terminal"
            tag="Add Terminal"
            handleClick={() =>
              navigate("add", { state: { name: tableData?.venue_name } })
            }
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  return (
    <>
      <PageHeader title={`Terminals Of ${tableData?.venue_name}`} />
      <PageCard>
        <div className="d-flex w-100 mb-2 justify-content-between responsive-tab-gap">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          {role !== "Viewer" && (
            <CustomButton
              text="Add New Terminal"
              type="btn-primary"
              handleClick={() =>
                navigate("add", { state: { name: tableData?.venue_name } })
              }
            />
          )}
        </div>

        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />
        {tableData?.totalRecords > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.totalRecords}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Terminal"
            onClick={handleDeletion}
          />
        )}
        {terminalModal.isTerminalModalOpen && (
          <DeleteModal
            name={terminalModal.name}
            id={terminalModal.modalId}
            isActive={terminalModal.isTerminalModalOpen}
            onClose={() =>
              setTerminalModal((prevState) => ({
                ...prevState,
                isTerminalModalOpen: false,
              }))
            }
            title="Terminal"
            type="Checkout"
            description={`Are you sure do you want checkout from ${terminalModal.name}.`}
            onClick={handleTerminalCheckout}
          />
        )}

        {modalState.isViewModalOpen && (
          <Modal
            name={`${modalState.name} Details`}
            title={`${modalState.name} Details`}
            id={modalState.modalId}
            isActive={modalState.isViewModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isViewModalOpen: false,
              }))
            }
            width="642px"
          >
            <ViewDetails data={modalState.data} />
          </Modal>
        )}
      </PageCard>
    </>
  );
};

export default Terminals;
