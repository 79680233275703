import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";

import Devices from "./tabs/devices/index";
import Designations from "./tabs/designations";
import Departments from "./tabs/departments";
import InviteeType from "./tabs/inviteeTypes";
import TabStrip from "../../../molecules/tabstrip";
import UserManagement from "./tabs/UserManagement";
import VenueAdmin from "./tabs/VenueAdmin";
import ActivityLogs from "./tabs/activityLogs";
import Settings from "./tabs/Settings";
import { useCookies } from "react-cookie";
import { decodeCookieValue } from "../../../../helpers/cookieHelper";

const Configurations = () => {
  const [activeTab, setActiveTab] = useState("Devices");
  const [cookies] = useCookies(['br'])

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Devices",
        view: <Devices key="devices" />,
      },
      {
        id: 2,
        label: "Designations",
        view: <Designations key="designations" />,
      },
      {
        id: 3,
        label: "Departments",
        view: <Departments />,
      },
      {
        id: 4,
        label: "Invitee Types",
        view: <InviteeType />,
      },
      {
        id: 5,
        label: "Venue Admin",
        view: <VenueAdmin />,
      },
      {
        id: 0,
        label: "User Management",
        view: <UserManagement />,
      },
      {
        id: 7,
        label: "General Settings",
        view: <Settings />,
      },
      {
        id: 6,
        label: "Activity Logs",
        view: <ActivityLogs />,
      },
    ].filter(item => !(item.label === "User Management" && decodeCookieValue(cookies.br) === "Manager")),
    [cookies.br]
  );
  
  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  return (
    <>
      <PageHeader title="Configurations" />
      <PageCard classes="p-3 pt-0">
        <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
        </Box>
      </PageCard>
    </>
  );
};

export default Configurations;
