import React from "react";
import styles from "./imagemodal.module.scss";

const ImageModal = ({ isActive = true, setIsActive, image}) => {
  return (
    <div
      className={styles.image_modal}
      style={{ display: isActive ? "block" : "none" }}
    >
      <span class={styles.close} data-dismiss="modal" onClick={() => setIsActive((prev) => ({...prev, isActive : false}))}>
        ×
      </span>
      <img src={image} alt='test' className={styles.modal_content} />
    </div>
  );
};

export default ImageModal;
