import React, { useEffect, useMemo, useState } from "react";
import PageCard from "../../../../atoms/PageCard";
import DateRangePickerShortCuts from "../../../../organisms/dateRangePicker";
import { Box } from "@mui/material";
import MeetInformation from "./MeetInformation";
import TabStrip from "../../../../molecules/tabstrip";
import addMonths from "date-fns/addMonths";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import {
  addHours,
  startOfDay,
  startOfMonth,
  endOfMonth,
} from "rsuite/esm/internals/utils/date";

const VisitorLogs = ({ switchedTab, venueData, selectedVenue }) => {
  const defaultToday = new Date();

  const [cookies] = useCookies(["bid", "b_t", "mode", "buid"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [date, setDate] = useState({
    start_date: addHours(startOfDay(startOfMonth(defaultToday)), 12),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)), 12),
  });
  const [dashboardData, setDashboardData] = useState({});

  const [activeTab, setActiveTab] = useState("Current Visitors");

  useEffect(() => {
    if (switchedTab) {
      setActiveTab(switchedTab);
    }
  }, [switchedTab]);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Current Visitors",
        view: (
          <MeetInformation
            type="current"
            data={dashboardData?.current_visitors}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setItemsPerPage}
          />
        ),
      },
      {
        id: 2,
        label: "Upcoming Visits",
        view: (
          <MeetInformation
            type="upcoming"
            data={dashboardData?.upcoming_visitors}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setItemsPerPage}
          />
        ),
      },
      // {
      //   id: 3,
      //   label: "History",
      //   view: (
      //     <MeetInformation
      //       type="history"
      //       data={dashboardData?.visitor_history}
      //       currentPage={currentPage}
      //       itemsPerPage={itemsPerPage}
      //       setCurrentPage={setCurrentPage}
      //       setItemsPerPage={setItemsPerPage}
      //       anchorEl={anchorEl}
      //       setAnchorEl={setAnchorEl}
      //       selectedRow={selectedRow}
      //       setSelectedRow={setSelectedRow}
      //     />
      //   ),
      // },
    ],
    [dashboardData]
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setCurrentPage(1);
    setItemsPerPage(25);
    setActiveTab(value);
  };

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const res = await getData({
          endpoint: "BusinessDashboard/getVisitorDetailsInDashboard",
          params: {
            start_date: date?.start_date,
            end_date: date?.end_date,
            business_id: cookies.bid,
            employee_id:
              atob(cookies?.mode || "") === "Employee" ? cookies.buid : null,
            page: currentPage,
            page_limit: itemsPerPage,
            venues:
              selectedVenue === "All"
                ? atob(cookies.mode) === "Employee"
                  ? JSON.stringify(
                      venueData
                        .filter((item) => item.label !== "All Venue")
                        .map((item) => item.value)
                    )
                  : null
                : selectedVenue
                ? JSON.stringify([selectedVenue])
                : null,
          },
          token: cookies.b_t,
        });
        if (res) {
          setDashboardData(res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getDashboardData();
  }, [
    date,
    cookies.b_t,
    cookies.bid,
    selectedVenue,
    venueData,
    currentPage,
    itemsPerPage,
  ]);

  useEffect(() => {}, [dashboardData]);

  return (
    <PageCard>
      <div>
        {" "}
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="h2-i">Visitor Logs</h3>
          <DateRangePickerShortCuts setDate={setDate} />
        </div>
        <div>
          <TabStrip
            activeTab={activeTab}
            onTabChange={onTabChange}
            data={data}
          />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
          </Box>
        </div>
      </div>
    </PageCard>
  );
};

export default VisitorLogs;
