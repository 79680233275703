import React, { useState, useEffect, useCallback } from "react";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import DateRangePickerShortCuts from "../../../organisms/dateRangePicker";
import { setLoading } from "../../../../store/slices/loaderSlice";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Pagination from "../../../molecules/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";
import toast from "react-hot-toast";
import { getData } from "../../../../services";
import PageCard from "../../../atoms/PageCard";
import { useDelete } from "../../../../hooks/useDelete";
import PageHeader from "../../../atoms/PageHeader";
import Search from "../../../molecules/search";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";
import ImageAndInitialViewer from "../../../molecules/ImageAndInitialViewer";
import FilterComponent from "../../../organisms/filters/FilterComponent";
import moment from "moment";
import addMonths from "date-fns/addMonths";
import { getEmployeeVenueList } from "../../../../services/basicGets";
import Modal from "../../../organisms/modals/Modal";
import ViewDetails from "../activities/View";
import CustomButton from "../../../atoms/CustomButton";
import Upload from "../../../icons/Upload";
import { confimationStyles } from "../../../../assets/styles/toast";
import { downloadReport } from "../../../../services/downloadService";

import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
} from "date-fns";

const Business = () => {
  const defaultToday = new Date();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [cookies] = useCookies(["e_t", "bid", "buid"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [venueData, setVenueData] = useState([]);
  const [filters, setFilters] = useState({});
  const [date, setDate] = useState({
    start_date: addHours(
      startOfDay(startOfMonth(defaultToday)),
      12
    ).toISOString(),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)), 12).toISOString(),
  });

  const [image, setImage] = useState({
    isActive: null,
    image: null,
  });
  const [selectedData, setSelectedData] = useState([]);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });

  const handleDownload = async () => {
    console.log("date", date);
    const res = await downloadReport({
      endpoint: "BusinessReport/getReportOfActivityofInvite",
      token: cookies.t,
      fileName: "ActivityReport",
      params: {
        business_id: cookies.bid,
        start_date: date.start_date,
        end_date: date.end_date,
        hosts:
          venueData?.length === 1 && !venueData?.[0]?.venue_admin
            ? JSON.stringify([cookies.buid])
            : null,
        venues:
          filters?.Venues?.length > 0
            ? JSON.stringify(filters?.Venues)
            : JSON.stringify(venueData.map((item) => item.value)) || null,
        status:
          filters?.status?.length > 0
            ? JSON.stringify(filters.status)
            : JSON.stringify(["Completed", "Ongoing"]),
        visitors:
          filters?.visitors?.length > 0
            ? JSON.stringify(filters?.visitors)
            : null,
      },
    });
    // console.log("RES", res);
    if (res) {
      toast.success("Visitor History Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const getTerminalData = useCallback(async () => {
    console.log("filters :", filters);

    try {
      setLoading(true);
      const res = await getData({
        endpoint: "Invite/getInvitesForActivity",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
          business_id: cookies.bid,
          employee_id: cookies.buid,
          start_date: date.start_date,
          end_date: date.end_date,
          hosts:
            venueData?.length === 1 && !venueData?.[0]?.venue_admin
              ? JSON.stringify([cookies.buid])
              : null,
          venues:
            filters?.Venues?.length > 0
              ? JSON.stringify(filters?.Venues)
              : JSON.stringify(venueData.map((item) => item.value)) || null,
          status:
            filters?.Status?.length > 0
              ? JSON.stringify(filters?.Status)
              : JSON.stringify(["Ongoing", "Completed"]),
        },

        token: cookies.e_t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [
    searchedValue,
    cookies.e_t,
    currentPage,
    itemsPerPage,
    selectedData,
    filters,
    venueData,
    date,
  ]);

  useEffect(() => {
    (async () => {
      const res = await getEmployeeVenueList({
        emp_id: cookies.buid,
        token: cookies.b_t,
      });
      setVenueData(res);
      if (state && state.statusCompleted) {
        setSelectedData([{ Status: state.statusCompleted }]);
      }
    })();
  }, []);

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.invite_id,
      data: data,
      name: data?.visitor_name,
    }));
  };

  useEffect(() => {
    getTerminalData();
  }, [getTerminalData]);

  console.table([modalState]);
  const columns = [
    {
      field: "photo",
      headerName: "Photo",
      minWidth: 70, // Minimum width for photo column
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              onClick={() => {
                if (params.row.check_in_photo) {
                  setImage({
                    isActive: true,
                    image: params.row.check_in_photo,
                  });
                } else {
                  return;
                }
              }}
            >
              <ImageAndInitialViewer
                image={params.row.check_in_photo}
                name={params.row.visitor_name}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "venue_name",
      headerName: "Venue",
      minWidth: 150, // Minimum width for venue column
      sortable: true,
    },
    {
      field: "visitor_name",
      headerName: "Visitor Name",
      minWidth: 200, // Minimum width for visitor name column
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span className="ms-2 text-ecp" title={params.row.visitor_name}>
              {params.row.visitor_name}
            </span>
            <span className="ms-1">
              {params.row?.addition_visitor?.length > 0
                ? `+${params.row.addition_visitor?.length}`
                : ""}
            </span>
          </>
        );
      },
    },
    {
      field: "host_name",
      headerName: "Host Name",
      minWidth: 150, // Minimum width for host name column
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200, // Minimum width for email column
      sortable: true,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      minWidth: 150, // Minimum width for contact number column
      sortable: true,
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150, // Minimum width for company column
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 200, // Minimum width for address column
    },
    {
      field: "purpose",
      headerName: "Purpose",
      minWidth: 200, // Minimum width for address column
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 200, // Minimum width for address column
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 150, // Minimum width for address column
      renderCell: (params) => {
        return (
          <>
            <span> {moment(params.row?.date).format("DD/MM/YYYY")}</span>
          </>
        );
      },
    },
    {
      field: "check_in_time",
      headerName: "Check In Time",
      minWidth: 200, // Minimum width for address column
      renderCell: (params) => {
        return (
          <>
            <span>
              {" "}
              {params.row?.check_in_time &&
                moment(params.row?.check_in_time).format("DD/MM/YYYY hh:mm a")}
            </span>
          </>
        );
      },
    },
    {
      field: "check_out_time",
      headerName: "Check Out Time",
      minWidth: 200, // Minimum width for address column
      renderCell: (params) => {
        return (
          <>
            <span>
              {" "}
              {params.row?.check_out_time &&
                moment(params.row?.check_out_time).format("DD/MM/YYYY hh:mm a")}
            </span>
          </>
        );
      },
    },
    {
      field: "stay_duration",
      headerName: "Stay Duration",
      minWidth: 150, // Minimum width for address column
      renderCell: (params) => {
        const time = params.row?.stay_duration
          ? moment(params.row?.stay_duration, "HH:mm:ss")
          : null;
        return (
          <>
            <span> {time && time.format("HH:mm")}</span>
          </>
        );
      },
    },
    {
      field: "overstayed_hours",
      headerName: "Overstayed Hours",
      minWidth: 200, // Minimum width for address column
      renderCell: (params) => {
        const time = params.row?.overstayed_hours
          ? moment(params.row?.overstayed_hours, "HH:mm:ss")
          : null;
        return (
          <>
            <span> {time && time.format("HH:mm")}</span>
          </>
        );
      },
    },
    {
      headerName: "Action",
      minWidth: 100, // Minimum width for action column
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={() => handleView(params.row)}>
              <View />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteTerminal = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard
          title="No Visitors Found"
          type="Company"
          icon={<NoContentIcon />}
          small={true}
        />
      </>
    );
  });

  const handleDeletion = async () => {
    try {
      await deleteTerminal("VenueTerminal/deleteVenueTerminal", "Terminal", {
        id: modalState.modalId,
      });
      getTerminalData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const filtersData = {
    Venues: venueData,
    Status: [
      { value: "Ongoing", label: "Ongoing" },
      { value: "Completed", label: "Completed" },
    ],
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <PageHeader title="Vistor History">
        <DateRangePickerShortCuts setDate={setDate} />
      </PageHeader>
      <PageCard>
        <div className="d-flex w-100 mb-2 justify-content-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          <div className="d-flex gap-3">
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              iconRequired
              icon={<Upload />}
            />

            <FilterComponent
              filterData={filtersData}
              onFilterChange={(filters) => {
                console.log("filters 2", filters);

                setFilters(filters);
              }}
              selectedData={selectedData}
            />
          </div>
        </div>

        <CustomDatagrid
          getRowId={(row) => row.invite_id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          height={() => 70}
        />
        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}

        {modalState.isViewModalOpen && (
          <Modal
            name={`${modalState.name}`}
            title={`${modalState.name}`}
            id={modalState.modalId}
            isActive={modalState.isViewModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isViewModalOpen: false,
              }))
            }
            width="640px"
          >
            <ViewDetails data={modalState.data} />
          </Modal>
        )}

        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Terminal"
            onClick={handleDeletion}
          />
        )}
      </PageCard>
    </>
  );
};

export default Business;
