export const isValidBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

export const decodeCookieValue = (cookieValue) => {
    if (cookieValue && isValidBase64(cookieValue)) {
      return atob(cookieValue);
    }
    return null;
  };