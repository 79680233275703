import React,{useState, useEffect} from 'react'
import { useLocation } from "react-router-dom";
import Heading from '../../../atoms/FormHeading'
import Loader from "../../../atoms/Loader";
import Form from './Form'
import { postData } from '../../../../services';
import { LINK_MODE_EMPLOYEE } from '../../../../helpers/constants';

const ResetOrCreatePass = ({type}) => {
  const [showForm, setShowForm] = useState(null);
  const [linkExpired, setLinkExpired] = useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  // Extract the user_id and expiry values
  const userId = params.get("user_id");
  const token = params.get("token");
  const expiry = new Date(params.get("expiry"));
  const module = params.get("module");
  const currentTime = new Date();

  const checkValidity = async () => {
    const endpoint = module === LINK_MODE_EMPLOYEE ? 'EmployeeAuth/checkLinkIsExpired' : 'BusinessAuth/checkLinkIsExpired';
    const res = await postData({
      endpoint,
      data: {},
      params: { user_id: userId },
    });
    
    if (res) {
      if (res?.data?.response === 400) {
        setLinkExpired(true);
      }
    }
  };

  useEffect(() => {
    checkValidity();
  },[])

  useEffect(() => {
    if (currentTime < expiry && !linkExpired) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location]);

  return (
    <>
        {showForm == null ? (
        <Loader />
      ) : showForm ? (
        <>
          <Heading
            title={type === "reset" ? "Reset Password" : "Create Password"}
            description={`Enter a new password to ${type} the password of your account. We’ll ask for this password whenever you log in.`}
          />
          <Form type={type} uid={userId} token={token} module={module}/>
        </>
      ) : (
        <Heading
          title="Link Expired"
          description="This link has already been expired. Please generate a new link."
          type="center"
        />
      )}
    </>
  )
}

export default ResetOrCreatePass